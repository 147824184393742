import { Link } from "gatsby";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import HelpBase from "../../components/help-base";
import {
  BRAND_NAME,
  FORUM_BASE_URL,
  HELPCENTER_BASE_URL,
} from "../../util/constants";

const HelpIndex = () => (
  <HelpBase title={`Help using ${BRAND_NAME}`}>
    <Row>
      <Col>
        <p>Need help or have questions? We're here for you!</p>
      </Col>
    </Row>
    <Row style={{ marginBottom: "1.0em" }}>
      <Col md={6}>
        <Card>
          <Card.Header>
            <b>How do I connect a TV?</b>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              By far the fastest and most reliable way to get your taps up on
              the big screen is to follow our{" "}
              <Link to={`/help/fire-tv-setup`}>FireTV Setup Guide</Link>.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <Card.Header>How much does {`${BRAND_NAME} `} cost?</Card.Header>
          <Card.Body>
            <Card.Text>
              For pricing information, see our{" "}
              <Link to={`/pricing`}>Pricing page</Link>.
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
      </Col>
    </Row>
    <Row>
      <Col md={{ span: 6, offset: 3 }}>
        <Card>
          <Card.Header>Got another question?</Card.Header>
          <Card.Body>
            <Card.Text>
              Visit our <a href={HELPCENTER_BASE_URL}>Full Help Center</a> for
              answers to many other questions, or pop by the{" "}
              <a href={FORUM_BASE_URL}>community forum</a> to chat with other
              users.{" "}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col>
        <hr />
        <em>
          Still need help? Contact us at{" "}
          <a href="mailto:support@taplist.io">support@taplist.io</a>.
        </em>
      </Col>
    </Row>
  </HelpBase>
);

export default HelpIndex;
